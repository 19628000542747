/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
}

h1 {
  margin: 10px 0;
  font-size: 2em;
  font-weight: 900;
}

h2 {
  margin: 10px 0;
  font-size: 1.5em;
  font-weight: 900;
}

h3 {
  margin: 10px 0;
  font-size: 1.25em;
  font-weight: 900;
}
